<template>
    <div class="pagination">
        <span>显示第&nbsp;{{ (currentPage - 1) * currentLength + 1 }}&nbsp;至&nbsp;{{
            end
        }}&nbsp;项结果，共&nbsp;{{ total }}&nbsp;项</span>
        <div style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
            <el-pagination background layout="prev, pager, next" :total="total" :current-page="currentPage"
                :page-size="currentLength" @current-change="handleQuery">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    props: ["handleQuery", "currentLength", "currentPage", "total"],
    computed: {
        end() {
            let t = (this.currentPage - 1) * this.currentLength + this.currentLength;
            if ((this.currentPage - 1) * this.currentLength == 0 && t > this.total) {
                return this.total
            }
            return t - (t >= this.total ? t % this.total : 0)
        }
    }
}
</script>
<style scoped>
.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666;
    font-size: 14px;
}
</style>
